
import {Options, Vue, VueBase} from 'vue-class-component';
import anime from 'animejs';
import Footer from "@/components/Footer.vue";
import ReactScrollImage from "@/components/ReactScrollImage.vue";
import {PropType} from "vue";
import { RouteLocationNormalized } from "vue-router";
import {getScrollParentElement} from "@/utils";

@Options<Production>({
  components: {
    Footer,
    ReactScrollImage
  },
  props:{
  }
})
export default class Production extends Vue {
  prevEl?:HTMLElement;
  route?:RouteLocationNormalized;

  beforeEnter(el:HTMLElement){
    anime.set(el,{
      opacity:0,
    });
  }

  enter(el:HTMLElement,done:()=>any){
    anime({
      targets:el,
      opacity:1,
      duration:300,
      easing:'linear',
      complete:() => {
        done();
      },
    });
  }
  leave(el:HTMLElement,done:()=>any){
    anime({
      targets:el,
      opacity:0,
      duration:200,
      easing:'easeInCubic',
      complete:() => {
        done();
        getScrollParentElement(this.$el).scrollTop = 0;
      }
    });
  }

}
