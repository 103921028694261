import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e279241"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "production" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReactScrollImage = _resolveComponent("ReactScrollImage")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ReactScrollImage, {
      class: "background-image",
      width: '100%',
      mode: 'scaleX',
      src: require('@/assets/images/bg-7.jpg')
    }, null, 8, ["src"]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, {
          class: "page-main-content",
          mode: "out-in",
          onBeforeEnter: _ctx.beforeEnter,
          onEnter: _ctx.enter,
          onLeave: _ctx.leave
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
          ]),
          _: 2
        }, 1032, ["onBeforeEnter", "onEnter", "onLeave"])
      ]),
      _: 1
    }),
    _createVNode(_component_Footer)
  ]))
}